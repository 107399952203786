import React from 'react'
import { useParams } from 'react-router-dom'
import { AccountsIcon, InvoicesIcon, OwnersIcon, SettingsIcon } from '@papertrail/styleguide'

import { MenuItemData } from '../../types'
import Menu from '../../components/Menu'

type Params = {
  id: string
}

export default function SubscriptionNav() {
  const params = useParams<Params>()
  const subscriptionId = params.id

  const subscriptionMenuItems: MenuItemData[] = [
    { icon: SettingsIcon, label: 'Settings', route: `/subscriptions/${subscriptionId}/overview` },
    { icon: AccountsIcon, label: 'Accounts', route: `/subscriptions/${subscriptionId}/accounts` },
    { icon: InvoicesIcon, label: 'Invoices', route: `/subscriptions/${subscriptionId}/invoices` },
    { icon: OwnersIcon, label: 'Owners', route: `/subscriptions/${subscriptionId}/owners` }
  ]

  if (subscriptionId) {
    return <Menu items={subscriptionMenuItems} />
  } else {
    return null
  }
}
