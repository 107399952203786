import React, { useRef } from 'react'
import './App.css'
import { BrowserRouter, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import AccountNav from './routes/account/AccountNav'
import SubscriptionNav from './routes/subscription/SubscriptionNav'
import ProfileNav from './routes/profile/ProfileNav'
import { Box, theme, ThemeProvider } from '@papertrail/styleguide'
import { Partner } from '@papertrail/types'
import { PapertrailSession } from '@papertrail/web3-session'

function RootNav() {
  const history = useHistory()
  const popoutContainer = useRef(null)
  const partner: Partner = PapertrailSession.partner

  function onLogoClick() {
    history.push('/')
  }

  const match = useRouteMatch({ path: '/accounts', exact: true })

  if (!match) {
    return (
      <Box id="left-navigation" ref={popoutContainer}>
        <div id="left-menu">
          {partner.images.appLogo && (
            <div
              id="left-menu-logo"
              onClick={onLogoClick}
              style={{ backgroundSize: '50px', backgroundImage: 'url(' + partner.images.appLogo + ')' }}
            />
          )}
          {!partner.images.appLogo && <div id="left-menu-logo" onClick={onLogoClick} />}
          <Switch>
            <Route
              path="/accounts/:id"
              render={(props) => <AccountNav popoutContainerRef={popoutContainer} {...props} />}
            />
            <Route path="/subscriptions/:id" component={SubscriptionNav} />
            <Route path="/profile" component={ProfileNav} />
          </Switch>
        </div>
      </Box>
    )
  }
}

export default function Root() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/accounts" />
          <Route path="/accounts/new-account" />
          <Route path="/accounts/new-subscription" />
          <Route component={RootNav} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}
